<template>
  <div>
    <header>
      <div class="logo">
        <img src="@/assets/images/logo.png" alt=""/>
      </div>
      <div class="header-r">
        <router-link to="/market">Launch APP</router-link>
      </div>
    </header>
    <div class="home-box">
      <img src="@/assets/images/meadow-fair-mo.jpg" alt="" class="back-img-mo"/>
      <img src="@/assets/images/meadow-fair.jpg" alt="" class="back-img"/>
      <img src="@/assets/images/star-1.png" alt="" class="common-star"/>
      <img src="@/assets/images/star-2.png" alt="" class="common-star"/>
      <div class="home-star">
        <img src="@/assets/images/shinning-3.png" alt=""/>
        <span class="span-1"></span>
        <span class="span-2" style="top: 4.92%; left: 44.2%; animation-delay: 1s"></span>
        <span class="span-2" style="top: 41.28%; right: 2.28%; animation-delay: 500ms"></span>
        <span class="span-2 span-3" style="top: 3.78%; left: 16.55%; animation-delay: 400ms"></span>
        <span class="span-2 span-3" style="top: 26%; right: 20.26%; animation-delay: 800ms"></span>
        <span class="span-2 span-4 span-5"
              style="width: 30px; height: 30px; top: 53.03%; left: 7.24%; animation-delay: 300ms"></span>
        <span class="span-2 span-4" style="top: 52.08%; left: 76.22%; animation-delay: 100ms"></span>
      </div>
      <div class="hoe">
        <img class="img1" src="@/assets/images/hoe.png"/>
        <img class="img2" src="@/assets/images/hoe-1.png"/>
        <img class="img3" src="@/assets/images/hoe-2.png"/>
      </div>
      <div class="shovel">
        <img class="img1" src="@/assets/images/shovel.png"/>
        <img class="img2" src="@/assets/images/shovel-1.png"/>
        <img class="img3" src="@/assets/images/shovel-3.png"/>
      </div>
    </div>
    <footer-content></footer-content>
  </div>
</template>

<script>
import {mobileRem} from '@/utils/rem.js'
import footerContent from "@/views/components/footer";

export default {
  name: 'home-box',
  components: {
    footerContent,
  },
  beforeMount(){
    document.body.setAttribute("style", "background: #888e9e");
  },
  beforeDestroy(){
    document.body.removeAttribute("style");
  },
  mounted() {
    mobileRem()
  },
};
</script>
<style scoped lang="scss">
header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7.3%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  .logo {
    width: 40px;
    height: 40px;
    img {
      width: 100%;
    }
  }

  .header-r {
    a {
      width: 150px;
      height: 40px;
      margin-top: 16px;
      font-size: 16px;
      font-family: 'SFPixelate-Bold';
      color: #000000;
      background: url('~@/assets/images/appbg.svg') no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: .8;

      &:hover {
        opacity: 1;
      }
    }
  }
}
.home-box {
  width: 100%;
  max-width: 1920px;
  min-width: 1200px;
  margin: 0 auto;
  position: relative;

  .back-img {
    width: 100%;
    display: block;
  }

  .back-img-mo {
    display: none;
  }

  .common-star {
    position: absolute;
    width: 10%;
    top: -15%;
    left: 34%;
    animation: animat 5s linear infinite;
    animation-delay: 2s;
    z-index: 1;

    &:nth-of-type(3) {
      width: 10%;
      top: -10%;
      left: 90%;
      animation: animat2 4s linear infinite;
    }

    @keyframes animat {
      0% {
        transform: translateY(0) translateX(0);
        opacity: 1;
      }
      25% {
        opacity: 0.8;
      }
      50% {
        transform: translateY(370%) translateX(-370%);
        opacity: 0;
      }
      100% {
        transform: translateY(370%) translateX(-370%);
        opacity: 0;
      }
    }
    @keyframes animat2 {
      0% {
        transform: translateY(0) translateX(0);
        opacity: 1;
      }
      25% {
        opacity: 0.8;
      }
      50% {
        transform: translateY(239%) translateX(-239%);
        opacity: 0;
      }
      100% {
        transform: translateY(239%) translateX(-239%);
        opacity: 0;
      }
    }
  }

  .home-star {
    width: 50.36%;
    position: absolute;
    top: 13.25%;
    left: 23.71%;
    max-width: 967px;

    img {
      width: 100%;
      height: 100%;
    }

    .span-1 {
      width: 143px;
      height: 157px;
      background: url('~@/assets/images/shinning-1.png') no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      background-size: 100% 100%;
      animation: flash 1s alternate infinite;
    }

    .span-2 {
      width: 48px;
      height: 48px;
      background: url('~@/assets/images/shinning-2.png') no-repeat;
      position: absolute;
      background-size: 100% 100%;
      animation: flash 1s alternate infinite;
    }

    .span-3 {
      width: 50px;
      height: 50px;
      background: url('~@/assets/images/shinning-4.png') no-repeat;
    }

    .span-4 {
      width: 21px;
      height: 21px;
      background: url('~@/assets/images/shinning-5.png') no-repeat;
    }
  }

  @keyframes flash {
    0% {
      opacity: 0.2;
      transform: scale(0.1);
    }
    60% {
      opacity: 0.6;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .hoe {
    position: absolute;
    bottom: 30.5%;
    right: 21.5%;
    width: 15.2%;
    max-width: 292px;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
    }

    @keyframes img1Tranm {
      0% {
        opacity: 1;
        visibility: visible;
      }
      32% {
        opacity: 1;
        visibility: visible;
      }
      33% {
        opacity: 0;
        visibility: hidden;
      }
      100% {
        opacity: 0;
        visibility: hidden;
      }
    }

    .img1 {
      animation: img1Tranm 2s linear infinite;
      animation-delay: 0s;
    }

    @keyframes img2Tranm {
      0% {
        opacity: 0;
        visibility: hidden;

      }
      33% {
        opacity: 0;
        visibility: hidden;
      }
      34% {
        opacity: 1;
        visibility: visible;
      }
      70% {
        opacity: 1;
        visibility: visible;
      }
      71% {
        opacity: 0;
        visibility: hidden;
      }
      100% {
        opacity: 0;
        visibility: hidden;
      }
    }

    .img2 {
      animation: img2Tranm 2s linear infinite;
      animation-delay: 0s;
    }

    @keyframes img3Tranm {
      0% {
        opacity: 0;
        visibility: hidden;
      }
      71% {
        opacity: 0;
        visibility: hidden;
      }
      72% {
        opacity: 1;
        visibility: visible;
      }
      100% {
        opacity: 1;
        visibility: visible;
      }
    }

    .img3 {
      animation: img3Tranm 2s linear infinite;
      animation-delay: 0s;
    }
  }

  .shovel {
    position: absolute;
    bottom: 27%;
    left: 11%;
    width: 13%;
    height: 12.5%;
    max-width: 257px;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
    }

    @keyframes img1Tranm {
      0% {
        opacity: 1;
        visibility: visible;
      }
      32% {
        opacity: 1;
        visibility: visible;
      }
      33% {
        opacity: 0;
        visibility: hidden;
      }
      100% {
        opacity: 0;
        visibility: hidden;
      }
    }

    .img1 {
      animation: img1Tranm 2s linear infinite;
      animation-delay: 0s;
    }

    @keyframes img2Tranm {
      0% {
        opacity: 0;
        visibility: hidden;

      }
      33% {
        opacity: 0;
        visibility: hidden;
      }
      34% {
        opacity: 1;
        visibility: visible;
      }
      70% {
        opacity: 1;
        visibility: visible;
      }
      71% {
        opacity: 0;
        visibility: hidden;
      }
      100% {
        opacity: 0;
        visibility: hidden;
      }
    }

    .img2 {
      animation: img2Tranm 2s linear infinite;
      animation-delay: 0s;
    }

    @keyframes img3Tranm {
      0% {
        opacity: 0;
        visibility: hidden;
      }
      71% {
        opacity: 0;
        visibility: hidden;
      }
      72% {
        opacity: 1;
        visibility: visible;
      }
      100% {
        opacity: 1;
        visibility: visible;
      }
    }

    .img3 {
      animation: img3Tranm 2s linear infinite;
      animation-delay: 0s;
    }
  }

  .home-link {
    z-index: 5;
    display: flex;
    position: fixed;
    bottom: 20px;
    left: 7.3%;

    li {
      width: 48px;
      height: 48px;
      background: url('~@/assets/images/gobg.png');
      background-size: 100%;
      margin-right: 16px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
          max-width: 60%;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  header {
    width: 100%;
    min-width: auto;
    padding: .32rem .4rem 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .logo {
      margin: 0;
      width: .8rem;
      height: .8rem;
    }

    .header-r {
      a {
        margin: 0;
        width: 3.02rem;
        height: .8rem;
        background-size: contain;
        font-size: .32rem;
      }
    }
  }
  .home-box {
    width: 100%;
    min-width: auto;
    overflow: hidden;
    min-height: 5rem;
    .back-img {
      display: none;
    }

    .back-img-mo {
      display: block;
      width: 100%;
    }

    .common-star {
      position: absolute;
      width: 2rem;
      top: -15%;
      left: 150%;
      animation: animat 5s linear infinite;
      animation-delay: 2s;
      z-index: 1;

      &:nth-of-type(3) {
        width: 2rem;
        top: -10%;
        left: 70%;
        animation: animat2 4s linear infinite;
      }
    }

    .home-star {
      width: 100%;
      position: absolute;
      top: 30%;
      left: 3%;

      .span-1 {
        width: 1.2rem;
        height: 1.3rem;
      }

      .span-2 {
        width: .4rem;
        height: .4rem;
        background: url('~@/assets/images/shinning-2.png') no-repeat;
        position: absolute;
        background-size: 100% 100%;
        animation: flash 1s alternate infinite;
      }

      .span-3 {
        width: .4rem;
        height: .4rem;
        background: url('~@/assets/images/shinning-4.png') no-repeat;
        background-size: contain;
      }

      .span-4 {
        width: .2rem;
        height: .20rem;
        background: url('~@/assets/images/shinning-5.png') no-repeat;
        background-size: contain;
      }

      .span-5 {
        width: .25rem !important;
        height: .25rem !important;
      }
    }

    .hoe {
      bottom: 19.2%;
      right: 14%;
      width: 1.8rem;
    }

    .shovel {
      bottom: 19%;
      left: 12.5%;
      width: 1.8rem;
    }

    .home-link {
      z-index: 5;
      display: flex;
      position: fixed;
      bottom: .86rem;
      left: .4rem;

      li {
        width: .96rem;
        height: .96rem;
        background: url('~@/assets/images/gobg.png');
        background-size: 100%;
        margin-right: .32rem;

        a {
          img {
            width: .54rem;
          }
        }
      }
    }
  }

}
@media screen and (min-width: 750px) and (max-width: 1280px){
  header{
    padding: 0 3%;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1600px){
  header{
    padding: 0 6%;
  }
}
</style>
